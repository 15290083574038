<template>
  <AuthLayout>
    <template #info>
      <InfoPanel title="Security. Privacy. Trust." subtitle="" />
    </template>
    <template #form>
      <div>
        <h3 class="text-sm mb-4 font-semibold">Reset your account MFA</h3>
        <div v-if="!hideForm">
          <p class="text-gray-dark text-tiny mb-4">
            Use the paper key given when account was created.
          </p>
          <ToznyForm
            :error="displayError"
            :loading="loading"
            :onSubmit="btnReset"
          >
            <ToznyInput
              v-model="email"
              id="email"
              data-auto-id="email"
              type="email"
              name="email"
              class="mb-6"
              placeholder="name@example.com"
              label="Email"
              float-label
            />
            <ToznyInput
              v-model="paperKey"
              type="password"
              id="paperKey"
              data-auto-id="paper-key-input"
              name="paperKey"
              class="mb-6"
              label="Paper Key"
              float-label
            />

            <div class="text-gray-dark text-tiny text-right mb-12">
              <div class="flex justify-between mb-12 text-tiny">
                <a href="login" class="no-underline text-tozny"
                  >Back to login</a
                >
              </div>
            </div>
            <ToznyButton
              class="text-center w-full py-4 rounded-lg"
              buttonText="Reset"
              :loading="loading"
              data-auto-id="recover-button"
            />
          </ToznyForm>
        </div>
        <div v-else>
          <p class="text-success text-medium text-left mt-12 mb-12">
            Your account MFA is reset. Please login.
          </p>
          <div class="text-gray-dark text-tiny text-right mb-12">
            <div class="flex justify-between mb-12 text-tiny">
              <a href="login" class="no-underline text-tozny">Back to login</a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AuthLayout>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import AuthLayout from '../../Common/AuthLayout/AuthLayout'
import InfoPanel from '../../Common/AuthLayout/InfoPanel'
import ToznyButton from '../../Common/ToznyButton'
import ToznyForm from '../../Common/ToznyForm'
import ToznyInput from '../../Common/ToznyInput'
export default {
  name: 'recovermfa',
  components: {
    AuthLayout,
    InfoPanel,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function () {
    return {
      email: '',
      paperKey: '',
      hideForm: false,
    }
  },
  computed: {
    ...mapState(['displayError', 'loading']),
  },
  methods: {
    ...mapActions('account', ['resetMFA']),
    async btnReset() {
      const reset = await this.resetMFA({
        email: this.email.toLowerCase(),
        paperKey: this.paperKey,
      })
      if (reset) {
        this.hideForm = true
      }
    },
  },
}
</script>
